import React from 'react';

import Layout from '../components/common/layout';
import Footer from '../components/common/Footer';
import Header from '../components/common/Header';
import Contact from '../components/contact/Contact';


const ContactPage = () => (
  <Layout>
    <Header />

    <div id="wrapper">
      <section id="main" className="wrapper">
        <div className="inner">
          <h1 className="major">Enquiries</h1>
          <Contact />
        </div>
      </section>
    </div>

    <Footer />
  </Layout>
);

export default ContactPage;
