import React from "react";

const ContactInformation = () =>
  <section>
    <ul className="contact">
      <li>
        <h3>Address</h3>
        <span>
          Rua vigia 269,
          <br />
          2775-298 Parede,
          <br />
          Portugal
        </span>
      </li>
      <li>
        <h3>Email</h3>
        <a href="mailto:climbing.sintra@gmail.com">climbing.sintra@gmail.com</a>
      </li>
      <li>
        <h3>Phone</h3>
        <span>(+351) 969 482 823</span>
      </li>
      <li>
        <h3>Social</h3>
        <ul className="icons">
          <li>
            <a href="https://www.facebook.com/SintraClimbingTours" className="fa-facebook">
              <span className="label">Facebook</span>
            </a>
          </li>
          <li>
            <a href="https://instagram.com/sintraclimbingtours" className="fa-instagram">
              <span className="label">Instagram</span>
            </a>
          </li>
        </ul>
      </li>
    </ul>
  </section>

export default ContactInformation;
