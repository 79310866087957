import React from 'react';
import { navigate } from 'gatsby-link';
import Recaptcha from 'react-google-recaptcha';

const RECAPTCHA_KEY = process.env.GATSBY_RECAPTCHA_KEY || '6LfgL60ZAAAAALFElJSN8ITZ9dn4NnwFxl-aqW_i';
if (typeof RECAPTCHA_KEY === 'undefined') {
//   throw new Error(`
//   Env var GATSBY_APP_SITE_RECAPTCHA_KEY is undefined! 
//   You probably forget to set it in your Netlify build environment variables. 
//   Make sure to get a Recaptcha key at https://www.netlify.com/docs/form-handling/#custom-recaptcha-2-with-your-own-settings
//   Note this demo is specifically for Recaptcha v2
//   `)
  console.log('No Recaptcha key found!');
}

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

function ContactForm() {
  const [state, setState] = React.useState({})
  const recaptchaRef = React.createRef()

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const form = e.target
    const recaptchaValue = recaptchaRef.current.getValue()
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        'g-recaptcha-response': recaptchaValue,
        ...state,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch((error) => alert(error))
  }

  return (
    <section>
      <form 
        method="post" 
        name="contact" 
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        data-netlify-recaptcha="true"
        action="/thanks/"
        onSubmit={handleSubmit}
      >
        {/* The `form-name` hidden field is required to support form submissions without JavaScript */}      
        <input type="hidden" name="form-name" value="contact"/>
        <div className="fields">
          <p hidden>
            <label>
              Don't fill this out: <input name="bot-field" onChange={handleChange} />
            </label>
          </p>
          <div className="field half">
            <label htmlFor="name">Name</label>
            <input type="text" name="name" id="name" onChange={handleChange} />
          </div>
          <div className="field half">
            <label htmlFor="phone_nr">Phone Number</label>
            <input type="text" name="phone_nr" id="phone_nr" onChange={handleChange} />
          </div>
          <div className="field">
            <label htmlFor="email">Email</label>
            <input type="email" name="email" id="email" onChange={handleChange} />
          </div>
          <div className="field">
            <label htmlFor="message">Message</label>
            <textarea name="message" id="message" rows="5" onChange={handleChange} />
          </div>
        </div>
        
        <ul className="actions">
          <li>
            <Recaptcha ref={recaptchaRef} sitekey={RECAPTCHA_KEY} />
          </li>
          <li>
            <button type="submit" className="button submit primary">
              Send Message
            </button>
          </li>
        </ul>
      </form>
    </section>
  )
}

export default ContactForm;
